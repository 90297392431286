dmx.Component('leaflet-search', {

  initialData: {
    results: [],
  },

  attributes: {
    provider: {
      type: String,
      default: 'OpenStreetMap',
      enum: ['OpenStreetMap', 'Bing', 'Esri', 'GeocodeEarth', 'Google', 'Here', 'LocationIQ', 'OpenCage', 'Pelias', 'Geoapify', 'GeoApiFr'],
    },

    params: { // params differ per provider
      type: Object,
      default: {},
    },

    controlStyle: {
      type: String,
      default: 'bar',
      enum: ['bar', 'button'],
    },

    position: {
      type: String,
      default: 'topleft',
      enum: ['topleft', 'topright', 'bottomleft', 'bottomright'],
    },

    showMarker: {
      type: Boolean,
      default: true,
    },

    showPopup: {
      type: Boolean,
      default: false,
    },

    maxMarkers: {
      type: Number,
      default: 1,
    },

    maxSuggestions: {
      type: Number,
      default: 5,
    },

    retainZoomLevel: {
      type: Boolean,
      default: false,
    },

    animateZoom: {
      type: Boolean,
      default: true,
    },

    searchLabel: {
      type: String,
      default: 'Enter address',
    },

    clearSearchLabel: {
      type: String,
      default: 'Clear search',
    },

    notFoundMessage: {
      type: String,
      default: '',
    },

    messageHideDelay: {
      type: Number,
      default: 3000,
    },

    zoomLevel: {
      type: Number,
      default: 18,
    },

    // Setting this to false is currently broken
    // Perhaps set default to false since OpenStreetMap provider doesn't support autocomplete and violates their ToS
    // https://github.com/smeijer/leaflet-geosearch/issues/347
    autoComplete: {
      type: Boolean,
      default: true,
    },

    autoCompleteDelay: {
      type: Number,
      default: 250,
    },

    autoClose: {
      type: Boolean,
      default: false,
    },
    
    keepResult: {
      type: Boolean,
      default: false,
    },

    updateMap: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
  },

  events: {
    results: Event,
  },

  render: false,

  init () {
    if (this.props.provider) {
      this._provider = new GeoSearch[this.props.provider + 'Provider']({ params: this.props.params });
      this._addControl();
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('provider') || updatedProps.has('params')) {
      this._provider = new GeoSearch[this.props.provider + 'Provider']({ params: this.props.params });
      this._addControl();
    }
  },

  destroy () {
    // cleanup here
    this._removeControl();
    this._provider = null;
  },

  _addControl () {
    if (this._searchControl) this._removeControl();

    this._searchControl = new GeoSearch.SearchControl({
      ...this.props,
      style: this.props.controlStyle,
      provider: this._provider,
    });

    requestAnimationFrame(() => {
      if (!this.parent.map) {
        console.warn('Parent map not found, make sure geo search is a child of a leaflet map.');
        return;
      }
      this._searchControl.addTo(this.parent.map);
    });
  },

  _removeControl () {
    this._searchControl.remove();
    this._searchControl = null;
  },

});