dmx.Component('geo-search', {

  initialData: {
    results: [],
  },

  attributes: {
    provider: {
      type: String,
      default: 'OpenStreetMap',
      enum: ['OpenStreetMap', 'Bing', 'Esri', 'GeocodeEarth', 'Google', 'Here', 'LocationIQ', 'OpenCage', 'Pelias', 'Geoapify', 'GeoApiFr'],
    },

    params: { // params differ per provider
      type: Object,
      default: {},
    }
  },

  methods: {
    search (query) {
      return this._search(query);
    },

    clearResults () {
      this.set({ results: [] });
      this.dispatchEvent('results');
    },
  },

  events: {
    results: Event,
  },

  render: false,

  init () {
    if (this.props.provider) {
      this._provider = new GeoSearch[this.props.provider + 'Provider']({ params: this.props.params });
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('provider') || updatedProps.has('params')) {
      this._provider = new GeoSearch[this.props.provider + 'Provider']({ params: this.props.params });
    }
  },

  destroy () {
    // cleanup here
    this._provider = null;
  },

  _search (query) {
    return this._provider.search({ query }).then(results => {
      for (const result of results) {
        result.longitude = result.x;
        result.latitude = result.y;
      }
      this.set({ results });
      this.dispatchEvent('results');
      return results;
    });
  }

});